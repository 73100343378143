.f-14-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.f-16-400 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.f-16-500 {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.f-24-500 {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.f-32-500 {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
}

.text-indent-16 {
  text-indent: 16px;
}
