form {
  // ensure enough room at the bottom of any form to display a one-line bmd-help
  margin-bottom: ($bmd-help-size-ratio * $font-size-base) * $line-height-base;

  .card & {
    margin: 0;
  }
  // reverse the above for navbars (no help expected in a navbar form)
  .navbar & {
    margin-bottom: 0; // only adjust bottom so that pull-xs-right flexed margin-left: auto works

    .bmd-form-group {
      display: inline-block;
      padding-top: 0;
    }

    .btn {
      margin-bottom: 0;
    }
  }
}

// -----
// Inputs
//
// Reference http://www.google.com/design/spec/components/text-fields.html
// MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
.form-control {
  background: $bmd-form-control-bg-repeat-y $bmd-form-control-bg-position;
  background-size: $bmd-form-control-bg-size;
  border: 0;
  height: 36px;
  transition: background 0s ease-out;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  font-size: $mdb-input-font-size-base;

  // The border bottom should be static in all states, the decorator will be animated over this.
  &:focus,
  .bmd-form-group.mat-focused & {
    background-size: $bmd-form-control-bg-size-active;
    //border-bottom: $input-btn-border-width solid $input-border-color;
    transition-duration: 0.3s;
  }

  @include material-placeholder() {
    color: $mdb-input-placeholder-color;
    font-weight: 400;
    font-size: $mdb-input-font-size-base;
  }

  .has-white & {
    @include material-placeholder() {
      color: $white-color;
    }
  }
}

// Help blocks (not in v4)
//  position: absolute approach - uses no vertical space and there is no form jumping, but text wrapping - not so good.
//  FIXME: width/wrapping isn't automatic and overflows occur.  What are some solutions?
//
.bmd-help {
  position: absolute;
  display: none;
  font-size: 0.8rem;
  font-weight: normal;
  @extend .text-muted;

  .bmd-form-group.mat-focused & {
    display: block;
  }

  //--------------------------------------
  // Multiple help blocks
  // - absolute positioning is used above to prevent bouncing
  // - when there is more than one, this will bounce but will at least show
  &:nth-of-type(2) {
    padding-top: 1rem; // the first one requires top padding to push it below the first one which is absolute positioned
  }

  + .bmd-help {
    position: relative;
    margin-bottom: 0;
  }
}

// -----
// State coloring: default, success, info, warning, danger
//
@include bmd-selection-color();
@include bmd-form-color($bmd-label-color, $bmd-label-color-focus, $input-border-color, $input-border-color);

.has-success {
  @include bmd-form-color($brand-success, $brand-success, $brand-success, $input-border-color);
}

.has-info {
  @include bmd-form-color($brand-info, $brand-info, $brand-info, $input-border-color);
}

.has-white {
  @include bmd-form-color($white-color, $white-color, $white-color, $input-border-color-white);

  .form-control {
    &:focus {
      color: $white-color;
    }
  }
}

.has-warning {
  @include bmd-form-color($brand-warning, $brand-warning, $brand-warning, $input-border-color);
}

.has-danger {
  @include bmd-form-color($brand-danger, $brand-danger, $brand-danger, $input-border-color);
}

.has-rose {
  @include bmd-form-color($brand-rose, $brand-rose, $brand-rose, $input-border-color);
}

// Reference http://www.google.com/design/spec/components/text-fields.html
// MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
//.variations(unquote(" label"), color, $bmd-input-placeholder-color);  // default label color variations

// Whereas .form-group adds structure, bmd-form-group just needs to make sure we have enough padding for our labels to work.  That's the only purpose.
.bmd-form-group {
  position: relative;

  // -----
  // Labels
  //
  // Reference http://www.google.com/design/spec/components/text-fields.html
  // MDL implementation: http://www.getmdl.io/components/index.html#textfields-section

  &:not(.has-success):not(.has-danger) {
    [class^="bmd-label"],
    [class*=" bmd-label"] {
      &.bmd-label-floating {
        color: $mdb-input-placeholder-color;
      }
    }
  }
  [class^="bmd-label"],
  [class*=" bmd-label"] {
    position: absolute;
    pointer-events: none;
    transition: 0.3s ease all;

    // hint to browser for optimization
    &.bmd-label-floating {
      will-change: left, top, contents;
      margin: 0;
      line-height: 1.4;
      font-weight: 400;
    }
  }

  // hide label-placeholders when the field is filled
  &.is-filled .bmd-label-placeholder {
    display: none;
  }

  // Optional class to make the text field inline collapsible/expandable (collapsed by default)
  //    This uses the BS collapse js to make the width expand.
  //    `width` class must also be on the element FIXME: do this with JS, it is a marker class and should be implicit because after all, we are an bmd-collapse-inline
  //    FIXME: js needs to do the focus on shown.bs.collapse event http://v4-alpha.getbootstrap.com/components/collapse/#events
  &.bmd-collapse-inline {
    display: flex;
    align-items: center;
    padding: 0; // get rid of any padding as this is a width transition
    min-height: 2.1em;

    // Expandable Holder.
    .collapse {
      flex: 1;
      display: none;
      &.show {
        // This is an unfortunate hack. Animating between widths in percent (%)
        // in many browsers (Chrome, Firefox) only animates the inner visual style
        // of the input - the outer bounding box still 'jumps'.
        // Thus assume a sensible maximum, and animate to/from that value.
        max-width: 1200px;
      }
    }

    .collapsing,
    .width:not(.collapse),
    // collapsing is removed and momentarily only width is present
    .collapse.show {
      display: block;
    }

    .collapsing {
      @include material-animation-default();
    }
  }

  // default floating size/location with an bmd-form-group
  @include bmd-form-size-variant(
    $font-size-base,
    $bmd-label-top-margin-base,
    $input-padding-y,
    $bmd-form-line-height,
    "bmd-form-group default"
  );

  // sm floating size/location
  &.bmd-form-group-sm {
    @include bmd-form-size-variant(
      $font-size-sm,
      $bmd-label-top-margin-sm,
      $input-padding-y-sm,
      $bmd-form-line-height-sm,
      "bmd-form-group sm"
    );
  }

  // lg floating size/location
  &.bmd-form-group-lg {
    @include bmd-form-size-variant(
      $font-size-lg,
      $bmd-label-top-margin-lg,
      $input-padding-y-lg,
      $bmd-form-line-height-sm,
      "bmd-form-group lg"
    );
  }
}

// default floating size/location without a form-group (will skip form-group styles, and just render default sizing variation) - IMPORTANT for non-form-group spacing such as radio/checkbox/switch
@include bmd-form-size-variant(
  $font-size-base,
  $bmd-label-top-margin-base,
  $input-padding-y,
  $bmd-form-line-height
);

select {
  &,
  &.form-control {
    // Use vendor prefixes as `appearance` isn't part of the CSS spec. OSX doesn't obey the border-radius: 0 without this.
    -moz-appearance: none;
    -webkit-appearance: none;
  }
}

// Input files - hide actual input - requires specific markup in the sample.
//.bmd-form-group input[type=file] {
//  opacity: 0;
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  z-index: 100;
//}

//
//
//.form-horizontal {
//
//  // Consistent vertical alignment of radios and checkboxes
//  .radio,
//  .checkbox,
//  .radio-inline,
//  .checkbox-inline {
//    padding-top: 0;
//  }
//
//  .radio {
//    margin-bottom: 10px;
//  }
//
//  label {
//    text-align: right;
//  }
//
//  label {
//    margin: 0;
//  }
//}

.form-inline {
  @include media-breakpoint-up(sm) {
    .input-group {
      display: inline-flex;
      align-items: center;
    }
  }
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  opacity: 0;

  .has-success & {
    color: $green;
    opacity: 1;
  }

  .has-danger & {
    color: $red;
    opacity: 1;
  }
}

.form-group {
  padding-bottom: 10px;
  position: relative;
  margin: 8px 0 0;
}

textarea {
  // height: auto !important;
  // resize: none;
  line-height: $bmd-line-height !important;
}

.form-group input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.form-newsletter {
  .input-group,
  .form-group {
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
    padding-top: 5px;
  }

  .btn {
    float: left;
    width: 20%;
    margin: 9px 0 0;
  }
}

.form-file-upload {
  .input-group-btn:last-child > .btn-round {
    border-radius: 30px;
  }

  .input-group-btn .btn {
    margin: 0;
  }

  .input-group {
    width: 100%;
  }
}

.input-group .input-group-btn {
  padding: 0 12px;
}

.form-control[disabled],
fieldset[disabled] .form-control,
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control {
  background-color: transparent;
  cursor: not-allowed;
  border-bottom: 1px dotted $input-border-color;
  background-repeat: no-repeat;
}

.mat-form-field {
  &.mat-focused {
    .mat-form-field-ripple {
      background-color: $brand-primary;
    }
    .mat-form-field-label {
      color: $brand-primary;
    }
  }
}

.mat-form-field {
  display: block !important;
}

.has-success {
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-empty.mat-form-field-label {
    color: #4caf50;
  }
  &.mat-focused {
    .mat-input-underline {
      .mat-input-ripple {
        background-color: #4caf50;
      }
    }
  }
}

.has-danger {
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-empty.mat-form-field-label {
    color: #f44336;
  }
  &.mat-focused {
    .mat-input-underline {
      .mat-input-ripple {
        background-color: #f44336;
      }
    }
  }
}
.ng-invalid {
  &.mat-focused .mat-form-field-label {
    color: #f44336;
  }
}

.mat-form-field {
  width: 100%;
}

.mat-form-field.has-success {
  .mat-input-underline {
    background-color: #4caf50;
    height: 2px;
    .mat-input-ripple {
      background-color: #4caf50;
    }
  }
  .mat-form-field-label {
    color: #4caf50;
  }
}
