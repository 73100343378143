.m-24 {
  margin: 24px;
}

.mx-24 {
  margin: 0 24px;
}

.my-24 {
  margin: 24px 0;
}

.p-24 {
  padding: 24px;
}

.px-24 {
  padding: 0 24px;
}

.py-24 {
  padding: 24px 0;
}
