/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import "../node_modules/@angular/cdk/overlay-prebuilt.css";

// angular material font-family override
@import "~@angular/material/theming";
$custom-typography: mat-typography-config(
  $font-family: "Poppins, sans-serif",
);
@include angular-material-typography($custom-typography);

// ag-grid styles
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

:root {
  --color-universal-primary: #ea7900;
  --color-universal-success: #004fc2;
  --color-universal-warning: #f5af1c;
  --color-universal-grey: #eeeeee;

  --font-weight-universal-bolder: 600;
  --font-weight-universal-bold: 500;
  --font-weight-universal-normal: 400;
  --font-weight-universal-light: 300;

  --font-size-universal-h1: 54px;
  --font-size-universal-h1-mobile: 48px;
  --font-size-universal-h2: 44px;
  --font-size-universal-h2-mobile: 36px;
  --font-size-universal-h3: 30px;
  --font-size-universal-h4: 24px;
  --font-size-universal-h5: 20px;
  --font-size-universal-h6: 18px;
  --font-size-universal-normal: 16px;
  --font-size-universal-small: 14px;
  --font-size-universal-extra-small: 12px;
}

* {
  font-size: 14px;
  font-weight: 400;
}

body {
  font-family: "Poppins", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif !important;
}

// button style
button {
  &.btn-success {
    background-color: var(--color-universal-success) !important;
  }

  &.btn-warning {
    background-color: var(--color-universal-primary) !important;
  }
}

// material components style
.mat-select-arrow {
  width: 0;
  height: 0;
  margin: 0 4px;
  border-top: 7px solid !important;
  border-left: 7px solid transparent !important;
  border-right: 7px solid transparent !important;
}

.mat-form-field-label {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.mat-input-element {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.mat-raised-button[disabled] {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-icon-button[disabled] {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
  color: var(--color-universal-warning);
}

.mat-tooltip {
  font-size: 12px !important;
  font-weight: 400 !important;
  text-align: center !important;
  background-color: rgba(0, 0, 0, 0.48) !important;
  color: white !important;
}

.custom-tooltip {
  font-size: 12px !important;
  border-radius: 15px !important;
  min-width: 240px !important;

  &.btn-tooltip {
    background-color: rgba(0, 0, 0, 0.36) !important;
  }
}

.input-tooltip {
  position: absolute;
  visibility: hidden;
  bottom: 1.5em;
  left: 7em;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.cdk-global-overlay-wrapper {
  z-index: 1000;
}

.cdk-overlay-container {
  z-index: 2000;
}

.cdk-overlay-pane {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.alert {
  z-index: 2000 !important;
}

// button loading bar class
@keyframes btnSpinner {
  to {
    transform: rotate(360deg);
  }
}

.btn-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: btnSpinner 0.8s linear infinite;
}

// material tab group
.mat-tab-label {
  &.mat-tab-label-active {
    opacity: 1 !important;
  }
}

.mat-tab-label-content {
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: var(--color-universal-primary) !important;
}

// material accordion
.mat-expansion-panel-header-title {
  font-size: 1.3em;
  font-weight: bold;
  color: #ff9800 !important;
}

.mat-error {
  font-size: 12px !important;
}

// SPC, Trust actions panel
.card.actions-panel {
  width: 320px !important;
  min-height: 440px !important;
  border-radius: 6px 0 0 6px !important;

  box-shadow: 0px 0px 8px 4px rgba(163, 157, 163, 1) !important;
  -moz-box-shadow: 0px 0px 8px 4px rgba(163, 157, 163, 1) !important;
  -webkit-box-shadow: 0px 0px 8px 4px rgba(163, 157, 163, 1) !important;

  .card-header {
    span {
      &.panel-title {
        font-size: 1.4em;
      }
    }
  }
}
